/* eslint-disable sonarjs/no-duplicate-string */
import { PANEL_TYPES } from 'constants/queryBuilder';
import { GetQueryResultsProps } from 'lib/dashboard/getQueryResults';
import { DataTypes } from 'types/api/queryBuilder/queryAutocompleteResponse';
import { EQueryType } from 'types/common/dashboard';
import { DataSource } from 'types/common/queryBuilder';

export const getPodQueryPayload = (
	clusterName: string,
	podName: string,
	start: number,
	end: number,
): GetQueryResultsProps[] => [
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'container_cpu_utilization--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'container_cpu_utilization',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: '6e050953',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '60fe5e62',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: '=',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{k8s_pod_name}}',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '9b92756a-b445-45f8-90f4-d26f3ef28f8f',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'container_memory_usage--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'container_memory_usage',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: 'a4250695',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '3b2bc32b',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: '=',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{k8s_pod_name}}',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: 'a22c1e03-4876-4b3e-9a96-a3c3a28f9c0f',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'container_cpu_utilization--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'container_cpu_utilization',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'A',
						filters: {
							items: [
								{
									id: '8426b52f',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '2f67240c',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: '=',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_container_cpu_request--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_container_cpu_request',
							type: 'Gauge',
						},
						aggregateOperator: 'latest',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'B',
						filters: {
							items: [
								{
									id: '8c4667e1',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: 'b16e7306',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: 'in',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'B',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'latest',
					},
				],
				queryFormulas: [
					{
						disabled: false,
						expression: 'A*100/B',
						legend: '{{k8s_pod_name}}',
						queryName: 'F1',
					},
				],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '7bb3a6f5-d1c6-4f2e-9cc9-7dcc46db398f',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'container_cpu_utilization--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'container_cpu_utilization',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'A',
						filters: {
							items: [
								{
									id: '0a862947',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: 'cd13fbf0',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: '=',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: 'usage - {{k8s_pod_name}}',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_container_cpu_limit--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_container_cpu_limit',
							type: 'Gauge',
						},
						aggregateOperator: 'latest',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'B',
						filters: {
							items: [
								{
									id: 'bfb8acf7',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: 'e09ba819',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: 'in',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: 'limit - {{k8s_pod_name}}',
						limit: null,
						orderBy: [],
						queryName: 'B',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'latest',
					},
				],
				queryFormulas: [
					{
						disabled: false,
						expression: 'A*100/B',
						legend: '{{k8s_pod_name}}',
						queryName: 'F1',
					},
				],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '6d5ccd81-0ea1-4fb9-a66b-7f0fe2f15165',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'container_memory_usage--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'container_memory_usage',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'A',
						filters: {
							items: [
								{
									id: 'ea3df3e7',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '39b21fe0',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: 'in',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_container_memory_request--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_container_memory_request',
							type: 'Gauge',
						},
						aggregateOperator: 'latest',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'B',
						filters: {
							items: [
								{
									id: '7401a4b9',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '7cdad1cb',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: '=',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'B',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'latest',
					},
				],
				queryFormulas: [
					{
						disabled: false,
						expression: 'A*100/B',
						legend: '{{k8s_pod_name}}',
						queryName: 'F1',
					},
				],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '4d03a0ff-4fa5-4b19-b397-97f80ba9e0ac',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},

		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'container_memory_usage--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'container_memory_usage',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'A',
						filters: {
							items: [
								{
									id: 'f2a3175c',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: 'fc17ff21',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: '=',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_container_memory_limit--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_container_memory_limit',
							type: 'Gauge',
						},
						aggregateOperator: 'latest',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'B',
						filters: {
							items: [
								{
									id: '175e96b7',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '1d9fbe48',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: 'in',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'B',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'latest',
					},
				],
				queryFormulas: [
					{
						disabled: false,
						expression: 'A*100/B',
						legend: '{{k8s_pod_name}}',
						queryName: 'F1',
					},
				],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: 'ad491f19-0f83-4dd4-bb8f-bec295c18d1b',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_pod_filesystem_available--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_pod_filesystem_available',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'A',
						filters: {
							items: [
								{
									id: '877385bf',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '877385cd',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: '=',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_pod_filesystem_capacity--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_pod_filesystem_capacity',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'B',
						filters: {
							items: [
								{
									id: '877385bf',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '877385cd',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: '=',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'B',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
				],
				queryFormulas: [
					{
						disabled: false,
						expression: '(B-A)/B',
						legend: '{{k8s_pod_name}}',
						queryName: 'F1',
					},
				],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '16908d4e-1565-4847-8d87-01ebb8fc494a',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		fillGaps: false,
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_pod_network_io--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_pod_network_io',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: '877385bf',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '9613b4da',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_pod_name--string--tag--false',
										isColumn: false,
										key: 'k8s_pod_name',
										type: 'tag',
									},
									op: '=',
									value: podName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_pod_name--string--tag--false',
								isColumn: false,
								key: 'k8s_pod_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{k8s_pod_name}}',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '4b255d6d-4cde-474d-8866-f4418583c18b',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
];

export const getNodeQueryPayload = (
	clusterName: string,
	nodeName: string,
	start: number,
	end: number,
): GetQueryResultsProps[] => [
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_node_cpu_time--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_node_cpu_time',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'A',
						filters: {
							items: [
								{
									id: '91223422',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '91223422',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_node_name--string--tag--false',
										isColumn: false,
										key: 'k8s_node_name',
										type: 'tag',
									},
									op: 'in',
									value: nodeName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_node_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_node_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{k8s_node_name}}',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_node_allocatable_cpu--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_node_allocatable_cpu',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'B',
						filters: {
							items: [
								{
									id: '9700f1d4',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_node_name--string--tag--false',
										isColumn: false,
										key: 'k8s_node_name',
										type: 'tag',
									},
									op: 'in',
									value: nodeName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_node_name--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'k8s_node_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{k8s_node_name}}',
						limit: null,
						orderBy: [],
						queryName: 'B',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
				],
				queryFormulas: [
					{
						disabled: false,
						expression: 'A/B',
						legend: '{{k8s_node_name}}',
						queryName: 'F1',
					},
				],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '259295b5-774d-4b2e-8a4f-e5dd63e6c38d',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		fillGaps: false,
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_node_memory_working_set--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_node_memory_working_set',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'A',
						filters: {
							items: [
								{
									id: 'a9f58cf3',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '8430c9a0',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_node_name--string--tag--false',
										isColumn: false,
										key: 'k8s_node_name',
										type: 'tag',
									},
									op: 'in',
									value: nodeName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_node_name--string--tag--false',
								isColumn: false,
								key: 'k8s_node_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_node_allocatable_memory--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_node_allocatable_memory',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'B',
						filters: {
							items: [
								{
									id: 'cb274856',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_node_name--string--tag--false',
										isColumn: false,
										key: 'k8s_node_name',
										type: 'tag',
									},
									op: 'in',
									value: nodeName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_node_name--string--tag--false',
								isColumn: false,
								key: 'k8s_node_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'B',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
				],
				queryFormulas: [
					{
						disabled: false,
						expression: 'A/B',
						legend: '{{k8s_node_name}}',
						queryName: 'F1',
					},
				],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '486af4da-2a1a-4b8f-992c-eba098d3a6f9',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		fillGaps: false,
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_node_network_io--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_node_network_io',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: '91223422',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: '66308505',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_node_name--string--tag--false',
										isColumn: false,
										key: 'k8s_node_name',
										type: 'tag',
									},
									op: 'in',
									value: nodeName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'interface--string--tag--false',
								isColumn: false,
								key: 'interface',
								type: 'tag',
							},
							{
								dataType: DataTypes.String,
								id: 'direction--string--tag--false',
								isColumn: false,
								key: 'direction',
								type: 'tag',
							},
							{
								dataType: DataTypes.String,
								id: 'k8s_node_name--string--tag--false',
								isColumn: false,
								key: 'k8s_node_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{k8s_node_name}}-{{interface}}-{{direction}}',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: 'b56143c0-7d2f-4425-97c5-65ad6fc87366',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_node_filesystem_available--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_node_filesystem_available',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'A',
						filters: {
							items: [
								{
									id: '91223422',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: 'a5dffef6',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_node_name--string--tag--false',
										isColumn: false,
										key: 'k8s_node_name',
										type: 'tag',
									},
									op: 'in',
									value: nodeName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_node_name--string--tag--false',
								isColumn: false,
								key: 'k8s_node_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'k8s_node_filesystem_capacity--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'k8s_node_filesystem_capacity',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'B',
						filters: {
							items: [
								{
									id: '91223422',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_cluster_name--string--tag--false',
										isColumn: false,
										key: 'k8s_cluster_name',
										type: 'tag',
									},
									op: '=',
									value: clusterName,
								},
								{
									id: 'c79d5a16',
									key: {
										dataType: DataTypes.String,
										id: 'k8s_node_name--string--tag--false',
										isColumn: false,
										key: 'k8s_node_name',
										type: 'tag',
									},
									op: 'in',
									value: nodeName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'k8s_node_name--string--tag--false',
								isColumn: false,
								key: 'k8s_node_name',
								type: 'tag',
							},
						],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'B',
						reduceTo: 'sum',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
				],
				queryFormulas: [
					{
						disabled: false,
						expression: '(B-A)/B',
						legend: '{{k8s_node_name}}',
						queryName: 'F1',
					},
				],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '57eeac15-615c-4a71-9c61-8e0c0c76b045',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
];

export const getHostQueryPayload = (
	hostName: string,
	start: number,
	end: number,
): GetQueryResultsProps[] => [
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_cpu_time--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'system_cpu_time',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'A',
						filters: {
							items: [
								{
									id: 'ad316791',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'state--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'state',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{state}}',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_cpu_time--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'system_cpu_time',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: true,
						expression: 'B',
						filters: {
							items: [
								{
									id: '6baf116b',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [],
						having: [],
						legend: '{{state}}',
						limit: null,
						orderBy: [],
						queryName: 'B',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
				],
				queryFormulas: [
					{
						disabled: false,
						expression: 'A/B',
						legend: '{{state}}',
						queryName: 'F1',
					},
				],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '315b15fa-ff0c-442f-89f8-2bf4fb1af2f2',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_memory_usage--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'system_memory_usage',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: '8026009e',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'state--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'state',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{state}}',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '40218bfb-a9b7-4974-aead-5bf666e139bf',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_cpu_load_average_1m--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'system_cpu_load_average_1m',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: '4167fbb1',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [],
						having: [],
						legend: '1m',
						limit: 30,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_cpu_load_average_5m--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'system_cpu_load_average_5m',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'B',
						filters: {
							items: [
								{
									id: '0c2cfeca',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [],
						having: [],
						legend: '5m',
						limit: 30,
						orderBy: [],
						queryName: 'B',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_cpu_load_average_15m--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'system_cpu_load_average_15m',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'C',
						filters: {
							items: [
								{
									id: '28693375',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [],
						having: [],
						legend: '15m',
						limit: 30,
						orderBy: [],
						queryName: 'C',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '8e6485ea-7018-43b0-ab27-b210f77b59ad',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_network_io--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'system_network_io',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: '3a03bc80',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'direction--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'direction',
								type: 'tag',
							},
							{
								dataType: DataTypes.String,
								id: 'device--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'device',
								type: 'tag',
							},
						],
						having: [
							{
								columnName: 'SUM(system_network_io)',
								op: '>',
								value: 0,
							},
						],
						legend: '{{device}}::{{direction}}',
						limit: 30,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '47173220-44df-4ef6-87f4-31e333c180c7',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_network_packets--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'system_network_packets',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: '3082ef53',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'direction--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'direction',
								type: 'tag',
							},
							{
								dataType: DataTypes.String,
								id: 'device--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'device',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{device}}::{{direction}}',
						limit: 30,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '62eedbc6-c8ad-4d13-80a8-129396e1d1dc',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_network_errors--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'system_network_errors',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: '8859bc50',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'direction--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'direction',
								type: 'tag',
							},
							{
								dataType: DataTypes.String,
								id: 'device--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'device',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{device}}::{{direction}}',
						limit: 30,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '5ddb1b38-53bb-46f5-b4fe-fe832d6b9b24',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_network_dropped--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'system_network_dropped',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: '40fec2e3',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'direction--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'direction',
								type: 'tag',
							},
							{
								dataType: DataTypes.String,
								id: 'device--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'device',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{device}}::{{direction}}',
						limit: 30,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: 'a849bcce-7684-4852-9134-530b45419b8f',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_network_connections--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'system_network_connections',
							type: 'Gauge',
						},
						aggregateOperator: 'avg',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: '87f665b5',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'protocol--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'protocol',
								type: 'tag',
							},
							{
								dataType: DataTypes.String,
								id: 'state--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'state',
								type: 'tag',
							},
						],
						having: [],
						legend: '{{protocol}}::{{state}}',
						limit: 30,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'avg',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: 'ab685a3d-fa4c-4663-8d94-c452e59038f3',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_disk_io--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'system_disk_io',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: '6039199f',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [],
						having: [],
						legend: '',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '9bd40b51-0790-4cdd-9718-551b2ded5926',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_disk_operation_time--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'system_disk_operation_time',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: 'd21dc017',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'device--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'device',
								type: 'tag',
							},
							{
								dataType: DataTypes.String,
								id: 'direction--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'direction',
								type: 'tag',
							},
						],
						having: [
							{
								columnName: 'SUM(system_disk_operation_time)',
								op: '>',
								value: 0,
							},
						],
						legend: '{{device}}::{{direction}}',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '9c6d18ad-89ff-4e38-a15a-440e72ed6ca8',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_disk_pending_operations--float64--Gauge--true',
							isColumn: true,
							isJSON: false,
							key: 'system_disk_pending_operations',
							type: 'Gauge',
						},
						aggregateOperator: 'max',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: 'a1023af9',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'device--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'device',
								type: 'tag',
							},
						],
						having: [
							{
								columnName: 'SUM(system_disk_pending_operations)',
								op: '>',
								value: 0,
							},
						],
						legend: '{{device}}',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'max',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: 'f4cfc2a5-78fc-42cc-8f4a-194c8c916132',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
	{
		selectedTime: 'GLOBAL_TIME',
		graphType: PANEL_TYPES.TIME_SERIES,
		query: {
			builder: {
				queryData: [
					{
						aggregateAttribute: {
							dataType: DataTypes.Float64,
							id: 'system_disk_operation_time--float64--Sum--true',
							isColumn: true,
							isJSON: false,
							key: 'system_disk_operation_time',
							type: 'Sum',
						},
						aggregateOperator: 'rate',
						dataSource: DataSource.METRICS,
						disabled: false,
						expression: 'A',
						filters: {
							items: [
								{
									id: 'd21dc017',
									key: {
										dataType: DataTypes.String,
										id: 'host_name--string--tag--false',
										isColumn: false,
										isJSON: false,
										key: 'host_name',
										type: 'tag',
									},
									op: '=',
									value: hostName,
								},
							],
							op: 'AND',
						},
						functions: [],
						groupBy: [
							{
								dataType: DataTypes.String,
								id: 'device--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'device',
								type: 'tag',
							},
							{
								dataType: DataTypes.String,
								id: 'direction--string--tag--false',
								isColumn: false,
								isJSON: false,
								key: 'direction',
								type: 'tag',
							},
						],
						having: [
							{
								columnName: 'SUM(system_disk_operation_time)',
								op: '>',
								value: 0,
							},
						],
						legend: '{{device}}::{{direction}}',
						limit: null,
						orderBy: [],
						queryName: 'A',
						reduceTo: 'avg',
						spaceAggregation: 'sum',
						stepInterval: 60,
						timeAggregation: 'rate',
					},
				],
				queryFormulas: [],
			},
			clickhouse_sql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			id: '9c6d18ad-89ff-4e38-a15a-440e72ed6ca8',
			promql: [
				{
					disabled: false,
					legend: '',
					name: 'A',
					query: '',
				},
			],
			queryType: EQueryType.QUERY_BUILDER,
		},
		variables: {},
		formatForWeb: false,
		start,
		end,
	},
];

export const podWidgetInfo = [
	{
		title: 'CPU usage',
		yAxisUnit: '',
	},
	{
		title: 'Memory Usage',
		yAxisUnit: 'bytes',
	},
	{
		title: 'Pod CPU usage [% of Request]',
		yAxisUnit: 'percent',
	},
	{
		title: 'Pod CPU usage [% of Limit]',
		yAxisUnit: 'percent',
	},
	{
		title: 'Pod memory usage [% of Request]',
		yAxisUnit: 'percent',
	},
	{
		title: 'Pod memory usage [% of Limit]',
		yAxisUnit: 'percent',
	},
	{
		title: 'Pod filesystem usage [%]',
		yAxisUnit: 'percentunit',
	},
	{
		title: 'Pod network IO',
		yAxisUnit: 'binBps',
	},
];

export const VIEW_TYPES = {
	NODE: 'node',
	POD: 'pod',
};

export const nodeWidgetInfo = [
	{
		title: 'Node CPU usage',
		yAxisUnit: 'percentunit',
	},
	{
		title: 'Node memory usage (WSS)',
		yAxisUnit: 'percentunit',
	},
	{
		title: 'Node network IO',
		yAxisUnit: 'binBps',
	},
	{
		title: 'Node filesystem usage',
		yAxisUnit: 'percentunit',
	},
];

export const hostWidgetInfo = [
	{ title: 'CPU Usage', yAxisUnit: 'percentunit' },
	{ title: 'Memory Usage', yAxisUnit: 'bytes' },
	{ title: 'System Load Average', yAxisUnit: '' },
	{ title: 'Network usage (bytes)', yAxisUnit: 'bytes' },
	{ title: 'Network usage (packet/s)', yAxisUnit: 'pps' },
	{ title: 'Network errors', yAxisUnit: 'short' },
	{ title: 'Network drops', yAxisUnit: 'short' },
	{ title: 'Network connections', yAxisUnit: 'short' },
	{ title: 'System disk io (bytes transferred)', yAxisUnit: 'bytes' },
	{ title: 'System disk operations/s', yAxisUnit: 'short' },
	{ title: 'Queue size', yAxisUnit: 'short' },
	{ title: 'Disk operations time', yAxisUnit: 's' },
];
