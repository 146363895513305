export enum LOCALSTORAGE {
	METRICS_TIME_IN_DURATION = 'metricsTimeDurations',
	IS_LOGGED_IN = 'IS_LOGGED_IN',
	AUTH_TOKEN = 'AUTH_TOKEN',
	REFRESH_AUTH_TOKEN = 'REFRESH_AUTH_TOKEN',
	THEME = 'THEME',
	LOGS_VIEW_MODE = 'LOGS_VIEW_MODE',
	LOGS_LINES_PER_ROW = 'LOGS_LINES_PER_ROW',
	LOGS_LIST_OPTIONS = 'LOGS_LIST_OPTIONS',
	TRACES_LIST_OPTIONS = 'TRACES_LIST_OPTIONS',
	GRAPH_VISIBILITY_STATES = 'GRAPH_VISIBILITY_STATES',
	TRACES_LIST_COLUMNS = 'TRACES_LIST_COLUMNS',
	LOGS_LIST_COLUMNS = 'LOGS_LIST_COLUMNS',
	LOGGED_IN_USER_NAME = 'LOGGED_IN_USER_NAME',
	LOGGED_IN_USER_EMAIL = 'LOGGED_IN_USER_EMAIL',
	CHAT_SUPPORT = 'CHAT_SUPPORT',
	IS_IDENTIFIED_USER = 'IS_IDENTIFIED_USER',
	DASHBOARD_VARIABLES = 'DASHBOARD_VARIABLES',
	SHOW_EXPLORER_TOOLBAR = 'SHOW_EXPLORER_TOOLBAR',
	PINNED_ATTRIBUTES = 'PINNED_ATTRIBUTES',
	THEME_ANALYTICS_V1 = 'THEME_ANALYTICS_V1',
	LAST_USED_SAVED_VIEWS = 'LAST_USED_SAVED_VIEWS',
	SHOW_LOGS_QUICK_FILTERS = 'SHOW_LOGS_QUICK_FILTERS',
	USER_ID = 'USER_ID',
	PREFERRED_TIMEZONE = 'PREFERRED_TIMEZONE',
	UNAUTHENTICATED_ROUTE_HIT = 'UNAUTHENTICATED_ROUTE_HIT',
	CELERY_OVERVIEW_COLUMNS = 'CELERY_OVERVIEW_COLUMNS',
	DONT_SHOW_SLOW_API_WARNING = 'DONT_SHOW_SLOW_API_WARNING',
	METRICS_LIST_OPTIONS = 'METRICS_LIST_OPTIONS',
}
